import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCountriesList() {
  // Use toast
  const toast = useToast()

  const refCountryListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'id', sortable: true },
    { key: 'name', label: 'Company Country Name', sortable: true },
    { key: 'externalID', label: 'Country', sortable: true },
    { key: 'regionID', label: 'Region', sortable: true },
    { key: 'isActive', label: 'Active', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalCountries = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)
  const rows = ref([])
  const allRegion = ref([])
  const allCountries = ref([])
  const dataMeta = computed(() => {
    const localItemsCount = refCountryListTable.value ? refCountryListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value,
      of: totalCountries.value,
    }
  })
  function GetAllData() {
    store
      .dispatch('apps-organisation-country/fetchAllCountries', {

      })
      .then(response => {
        const data = response.data.filter(x => x.isDelete == false)
        rows.value = data
        totalCountries.value = data.length
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Country list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const refetchData = () => {
    GetAllData()
    GetAllRegion()
    GetAllCountries()
    refCountryListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })
  function GetAllRegion() {
    store
      .dispatch('apps-organisation-country/fetchAllRegions', {

      })
      .then(response => {
        const data = response.data.filter(x => x.isDelete == false)
        allRegion.value = data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Country list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  function GetAllCountries() {
    store
      .dispatch('apps-organisation-country/fetchCountries', {
      })
      .then(response => {
        allCountries.value = response.data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Country list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  GetAllData()
  GetAllRegion()
  GetAllCountries()
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    rows,
    tableColumns,
    perPage,
    currentPage,
    totalCountries,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCountryListTable,
    allRegion,
    allCountries,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
