import axios from '@axios'

export const GET_COUNTRY = 'getCountry'
export const SET_COUNTRY = 'setCountry'

export default {
  namespaced: true,
  state: {
    country: {},
  },
  getters: {
    getCountry(state) {
      return state.country
    },
  },
  mutations: {
    [SET_COUNTRY](state, val) {
      state.country = val
    },
  },
  actions: {
    fetchAllCountries() {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/Country')
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchAllRegions() {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/Region')
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchCountry(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`pamis/admin/organization/Country/GetByID?id=${id}`)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },
    addCountry(ctx, CountryFormData) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/organization/Country/Create', CountryFormData)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },
    updateCountry(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`pamis/admin/organization/Country/Update?id=${params.id}`, params)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },
    deleteCountry(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`pamis/admin/organization/Country/Delete?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountries() {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/Country/ExternalCountries')
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
