<template>
  <b-sidebar
    id="add-new-country-sidebar"
    :visible="isAddNewCountrySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-country-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ isDelete ? 'Delete Country Info' : localCountry.id > 0 ? 'Update Country ' : 'Add New Country' }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(handleCountrySubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Country Name"
            rules="required"
          >
            <b-form-group
              label="Country Name"
              label-for="Country"
            >
              <v-select
                id="CounrtId"
                v-model="selectedCountry"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countryName"
                label="name"
                v-on:input="changeCountry"
                placeholder="Select"
                :disabled="isDelete"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="CountryName"
            rules="required"
          >
          <b-form-group
            label="Company Country Name"
            label-for="CountryName"
          >
            <b-form-input
              id="CountryName"
              v-model="localCountry.name"
              :disabled="isDelete"
              autofocus
              trim
              placeholder="Company Country Name"
            />
          <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="RegionId"
            rules="required"
          >
            <b-form-group
              label="Region"
              label-for="RegionId"
            >
              <v-select
                id="RegionId"
                v-model="selectedRegion"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="regionName"
                label="name"
                placeholder="Select"
                :disabled="isDelete"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="isActive"
          >
            <b-form-group
              label="isActive"
              label-for="Active"
            >
              <b-form-checkbox
                id="isActive"
                v-model="localCountry.isActive"
                :checked="localCountry.isActive"
                :disabled="isDelete"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-show="isDelete ? false:true"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ localCountry.id > 0 ? 'Update' : 'Add' }}
            </b-button>
            <b-button
              v-show="isDelete"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="mr-2"
              variant="outline-danger"
              @click="deleteCountry"
            >
              Delete
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback,
  BButton, BFormTextarea, BRow, BCol, BFormCheckbox, BFormTimepicker, BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BFormTimepicker,
    BFormDatepicker,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewCountrySidebarActive',
    event: 'update:is-add-new-country-sidebar-active',
  },
  props: {
    isAddNewCountrySidebarActive: {
      type: Boolean,
      required: true,
    },
    activeCountry: null,
    regions: [],
    countries: [],
    isDelete: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      localCountry: {
        id: null,
        externalID: 0,
        name: '',
        regionID: 0,
        isActive: false,
      },
      selectedRegion: null,
      regionName: [],
      selectedCountry: null,
      countryName: [],
    }
  },
  watch: {
    activeCountry(newVal, oldVal) {
      if (newVal) {
        this.localCountry = newVal
        this.regionName = this.regions
        this.countryName = this.countries

        if (newVal.id != null) {
          this.selectedRegion = this.regionName.filter(x => x.id == newVal.regionID)[0]
          this.selectedCountry = this.countryName.filter(x => x.id == newVal.externalID)[0]
          this.localCountry.name  == '' ? selectedCountry.name : this.localCountry.name
        } else {
          this.selectedRegion = null;
          this.selectedCountry = null;
          this.localCountry.name = null;
        }
      }
    }
  },
  
  methods: {
    changeCountry(){
      this.localCountry.name = this.selectedCountry.name;
    },
    resetForm() {
      this.localCountry = {
        id: null,
        externalID: 0,
        regionID: 0,
        isActive: false,
      }
      // this.activeCountry = null
      // this.regions = null
      this.isDelete = false
      this.$emit('refetch-data')
      this.$emit('update:is-add-new-country-sidebar-active', false)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    handleCountrySubmit() {
      this.localCountry.regionID = this.selectedRegion.id
      this.localCountry.externalID = this.selectedCountry.id
      if (this.localCountry.id > 0) {
        this.UpdateForm()
      } else {
        this.SaveForm()
      }
      // this.isAddNewCountrySidebarActive = false
      this.resetForm()
      this.$emit('update:is-add-new-country-sidebar-active', false)
    },
    SaveForm() {
      console.log(this.localCountry)
      store.dispatch('apps-organisation-country/addCountry', this.localCountry)
        .then(() => {
          emit('refetch-data')
          this.notification()
        })
    },
    UpdateForm() {
      store.dispatch('apps-organisation-country/updateCountry', this.localCountry)
        .then(() => {
          emit('refetch-data')
          this.notification()
        })
    },
    deleteCountry() {
      if (this.localCountry.id <= 0) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }
      store.dispatch('apps-organisation-country/deleteCountry', this.localCountry.id)
        .then(response => {
          const result = response.data.success
          this.resetForm()
          this.notification()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.isLoading = false
          this.isUpdated = false
        })
    },
    notification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Process Successfully',
          icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-country-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
